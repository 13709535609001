(function () {
  'use strict';

  /* @ngdoc object
   * @name informacio.image
   * @description
   *
   */
  angular
  .module('neo.home.ajuntaments.ajuntament.informacio.image', [
    'ui.router'
  ]);
}());
